import { useCallback, useMemo } from "react";

import {
  type CloudFlowNodeType,
  CustomFrequencies,
  Frequencies,
  NODE_STATUS,
  type NodeConfigScheduleTriggerParameters,
} from "@doitintl/cmp-models";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Autocomplete, Box, Button, Card, CardHeader, Grid, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useFormik } from "formik";
import * as Yup from "yup";

import { cloudflowTexts } from "../../../../../assets/texts";
import { allTimezones } from "../../../../../Components/NotificationSubscription/allTimeZones";
import { RequiredLabel } from "../../../../../Components/NotificationSubscription/helpers";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const validationSchema = Yup.object({
  timeZone: Yup.string().required(cloudflowTexts.VALIDATION.TIME_ZONE_REQUIRED).default(""),
  startDate: Yup.date()
    .typeError(cloudflowTexts.VALIDATION.INVALID_DATE)
    .required(cloudflowTexts.VALIDATION.START_DATE_REQUIRED)
    .default(null),
  time: Yup.date()
    .typeError(cloudflowTexts.VALIDATION.INVALID_TIME)
    .required(cloudflowTexts.VALIDATION.TIME_REQUIRED)
    .default(null),
  frequency: Yup.string()
    .oneOf(Object.values(Frequencies))
    .required(cloudflowTexts.VALIDATION.FREQUENCY_REQUIRED)
    .default(null),
  customFrequencyAmount: Yup.number()
    .when("frequency", ([frequency], schema) => {
      if (frequency === Frequencies.Custom) {
        return schema
          .required(cloudflowTexts.VALIDATION.REPEATS_EVERY_REQUIRED)
          .integer()
          .typeError(cloudflowTexts.VALIDATION.INVALID_NUMBER)
          .min(1, cloudflowTexts.VALIDATION.REPEATS_EVERY_MIN);
      }
      return schema.notRequired().nullable();
    })
    .default(null),
  customFrequency: Yup.string()
    .when("frequency", ([frequency], schema) => {
      if (frequency === Frequencies.Custom) {
        return schema
          .oneOf(Object.values(CustomFrequencies), cloudflowTexts.VALIDATION.CUSTOM_FREQUENCY_REQUIRED)
          .required(cloudflowTexts.VALIDATION.CUSTOM_FREQUENCY_REQUIRED);
      }
      return schema.notRequired().nullable();
    })
    .default(null),
});
const ScheduleTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.TRIGGER>();

  const {
    palette: { primary },
  } = useTheme();
  const { updateNode, onChangeTriggerType } = useNodeConfigurationContext<CloudFlowNodeType.TRIGGER>();

  const initialValues = useMemo(() => {
    const defaultValues = validationSchema.getDefault();
    return {
      ...defaultValues,
      ...nodeConfig.parameters,
    };
  }, [nodeConfig.parameters]);

  const { errors, values, handleBlur, touched, setValues, validateForm } =
    useFormik<NodeConfigScheduleTriggerParameters>({
      initialValues,
      validationSchema,
      onSubmit: () => {},
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
    });

  const saveParameters = useCallback(
    async (newParams) => {
      setValues((prev) => {
        const updatedValues = { ...prev, ...newParams };
        return updatedValues;
      });

      const formErrors = await validateForm({ ...values, ...newParams });

      const isValid = Object.keys(formErrors).length === 0;
      const errorText = Object.values(formErrors).filter(Boolean).join(", ");

      updateNode((prevNode) => {
        const updatedNode = {
          ...prevNode,
          parameters: { ...values, ...newParams },
          status: isValid ? NODE_STATUS.VALIDATED : NODE_STATUS.ERROR,
          errors: isValid
            ? { ...prevNode.errors, param_error: undefined }
            : {
                ...prevNode.errors,
                param_error: errorText,
              },
        };
        return updatedNode;
      });
    },
    [setValues, validateForm, updateNode, values]
  );

  return (
    <Box p={2}>
      <Stack alignContent="center">
        <Card sx={{ "&:hover": { borderColor: primary.main } }}>
          <CardHeader
            subheader="Trigger set on a custom schedule"
            title="Scheduled"
            titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
            subheaderTypographyProps={{ variant: "caption" }}
            action={<Button onClick={onChangeTriggerType}>Change</Button>}
            sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
            avatar={<TimerOutlinedIcon color="primary" />}
          />
        </Card>
        <Box mb={2} />
        <Typography variant="subtitle2">Parameters</Typography>
        <Box mb={2} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={allTimezones}
              value={values.timeZone}
              onChange={(_event, newValue) => {
                saveParameters({ timeZone: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={RequiredLabel("Time zone")}
                  variant="outlined"
                  fullWidth
                  name="timeZone"
                  onBlur={handleBlur}
                  error={touched.timeZone && Boolean(errors.timeZone)}
                  helperText={touched.timeZone && errors.timeZone?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              disableMaskedInput
              label={RequiredLabel("Start date")}
              value={values.startDate}
              onChange={(newValue) => {
                saveParameters({ startDate: newValue });
              }}
              minDate={new Date()}
              inputFormat="dd MMMM yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="startDate"
                  onBlur={handleBlur}
                  error={touched.startDate && Boolean(errors.startDate)}
                  helperText={touched.startDate && errors.startDate?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                ampm={false}
                label={RequiredLabel("Time")}
                value={values.time}
                onChange={(value) => {
                  saveParameters({ time: value });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="time"
                    onBlur={handleBlur}
                    error={touched.time && Boolean(errors.time)}
                    helperText={touched.time && errors.time?.toString()}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={Object.values(Frequencies)}
              value={values.frequency}
              onChange={(_event, frequency) => {
                saveParameters({ frequency });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={RequiredLabel("Frequency")}
                  variant="outlined"
                  fullWidth
                  name="frequency"
                  onBlur={handleBlur}
                  error={touched.frequency && Boolean(errors.frequency)}
                  helperText={touched.frequency && errors.frequency?.toString()}
                />
              )}
              fullWidth
              aria-required={true}
            />
          </Grid>
          <Grid item xs={12}>
            {values.frequency === Frequencies.Custom && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label={RequiredLabel("Repeats every")}
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{ min: 1 }}
                    value={values.customFrequencyAmount}
                    onChange={(event) => {
                      saveParameters({ customFrequencyAmount: parseInt(event.target.value) });
                    }}
                    onBlur={handleBlur}
                    name="customFrequencyAmount"
                    error={touched.customFrequencyAmount && Boolean(errors.customFrequencyAmount)}
                    helperText={touched.customFrequencyAmount && errors.customFrequencyAmount?.toString()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={Object.values(CustomFrequencies)}
                    value={values.customFrequency}
                    onChange={(_event, customFrequency) => {
                      saveParameters({ customFrequency });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={RequiredLabel("Frequency")}
                        variant="outlined"
                        fullWidth
                        name="customFrequency"
                        onBlur={handleBlur}
                        error={touched.customFrequency && Boolean(errors.customFrequency)}
                        helperText={touched.customFrequency && errors.customFrequency?.toString()}
                      />
                    )}
                    fullWidth
                    aria-required={true}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ScheduleTab;
